.SelectFileModal-upload-fields {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: calc(100vw - 40px);
    width: 600px;
}

.SelectFileModal-upload-fields .InputText,
.SelectFileModal-upload-fields .InputFile {
    flex-grow: 1;
    flex-basis: 200px;
    flex-shrink: 0;
    margin-right: 10px;
}

.SelectFileModal-files {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 -10px;
}

.SelectFileModal-file {
    width: 100px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
}

.SelectFileModal-file-preview img {
    width: 100px;
    height: 100px;
}

.SelectFileModal-file-title {
    height: 30px;
    padding: 10px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
}

.SelectFileModal-files-empty {
    flex-grow: 1;
    flex-shrink: 1;
    text-align: center;
    padding: 20px;
}
