.SelectEventLocationModal-eventlocations {
    border-top: 1px solid #333;
    margin-left: -30px;
    margin-right: -30px;
    padding: 0 30px;
    margin-top: 30px;
}

.SelectEventLocationModal-eventlocation {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.SelectEventLocationModal-eventlocation:not(:last-of-type) {
    border-bottom: 1px solid #333;
}

.SelectEventLocationModal-eventlocation-details {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 20px;
}

.SelectEventLocationModal-eventlocation-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.SelectEventLocationModal-eventlocation-action {
    padding: 10px 20px;
    cursor: pointer;
    text-decoration: none;
    color: #333;
}
