.SlideShow {
    overflow: hidden;
    position: relative;
    max-width: 600px;
    width: 100%;
    margin: 20px auto;
    background: #fff;
    border: 10px solid #2b2b2b;
    box-sizing: border-box;
}

.SlideShow-images {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    transition: left ease-in-out 0.7s;
    width: 100%;
    height: 100%;
}

.SlideShow-image {
    width: 100%;
    height: 100%;
    text-align: center;
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    background-color: #000;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 75%;
}

.SlideShow-nav {
    opacity: 0;
    transition: opacity ease 0.3s;
    font-size: 100px;
}

.SlideShow:hover .SlideShow-nav {
    opacity: 0.2;
}

.SlideShow-nav-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    color: #000;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.SlideShow-nav-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    color: #000;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}