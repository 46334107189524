.BlogPage-hero-keke {
    background-image: url(./assets/hero_keke.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    position: absolute;
    bottom: 4%;
    left: 40%;
    right: 0;
    height: 68%;
}

.BlogPage-blogitems {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
}

.BlogPage-blogitem {
    margin-bottom: 100px;
    display: block;
    text-decoration: none;
    color: inherit;
    text-align: left;
    position: relative;
}

.BlogPage-blogitem:not(:last-of-type)::after {
    display: block;
    content: '';
    position: relative;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    height: 2px;
    background: #bbae79;
}

.BlogPage-empty {
    text-align: center;
    display: block;
    padding: 30px 20px;
}

.BlogPage-blogitem-details {
    outline: none;
    color: inherit;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.BlogPage-blogitem-image {
    width: 200px;
    height: 112px;
    background-size: cover;
    background-position: center;
    background-color: #ccc;
    margin-left: 20px;
    flex-grow: 0;
    flex-shrink: 0;
}

.BlogPage-blogitem-datetime {
    color: rgba(0,0,0,0.5);
    margin-bottom: 20px;
    font-style: italic;
}

.BlogPage-blogitem-more {
    color: rgba(0,0,0,0.5);
    margin-top: 20px;
    font-style: italic;
}

.BlogPage-blogitem-abstract {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
}

@media (max-width: 800px) {
    .BlogPage-blogitem-details {
        flex-direction: column-reverse;
    }

    .BlogPage-blogitem-image {
        margin-left: 0;
        margin-bottom: 20px;
    }
}
