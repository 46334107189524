.EventPreview {
    position: relative;
    width: 300px;
    height: 300px;
    text-decoration: none;
    color: #333;
    display: inline-block;
}

.EventPreview-image {
    flex-grow: 0;
    flex-shrink: 0;
    width: 270px;
    height: 290px;
    position: relative;
    background-color: #000;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-image: image(url(./assets/mask.png), #000);
    mask-image: url(./assets/mask.png);
}

.EventPreview-info {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 20px;
    color: #fff;
    background-color: #1d4f58;
    opacity: 0.9;
}
