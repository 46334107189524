.AdminEditEventPage .PageContent {
    max-width: 1200px;
    text-align: left;
    padding-bottom: 100px;
}

.AdminEditEventPage-date {
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #333;
}

.AdminEditEventPage-date-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.AdminEditEventPage-date-date {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 10px 20px;
}

.AdminEditEventPage-date-time {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 10px 20px;
}

.AdminEditEventPage-date-status {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 10px 20px;
}

.AdminEditEventPage-date-actions {
    flex-grow: 0;
    flex-shrink: 0;
    text-align: right;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
}

.AdminEditEventPage-date-details {
    padding: 10px 20px;
}
