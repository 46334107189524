.PageContent {
    max-width: 900px;
    width: calc(100% - 20px);
    margin: 0 auto;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.PageContent.noheader {
    padding-top: 100px;
}

.PageContent h1 {
    line-height: 1.2;
    overflow: hidden;
    overflow-wrap: break-word;
}
