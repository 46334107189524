.LocationPage-hero-img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    mask-image: linear-gradient(to right, rgba(0,0,0,0) 0%,  rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 100%)
}