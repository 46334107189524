.ArchivePage-empty {
    text-align: center;
    display: block;
    padding: 30px 20px;
}

.ArchivePage-events {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.ArchivePage-event {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 20px 0;
}
