.NavBar-admin {
    background-color: #226;
    color: #fff;
    font-size: 15px;
}

.NavBar-main {
    height: 70px;
    background-color: transparent;
    font-size: 15px;
}

.NavBar-inner,
.NavBar-admin-inner {
    max-width: 950px;
    width: calc(100% - 20px);
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.NavBar-logo {
    flex-grow: 0;
    flex-shrink: 1;
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 20px;
}

.NavBar-logo > img {
    width: 100%;
    max-width: 120px;
}

.NavBar-space {
    flex-grow: 1;
    flex-shrink: 0;
}

.NavBar-menubutton {
    padding: 10px;
    display: none;
}

.NavBar-items,
.NavBar-admin-items {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.NavBar-item {
    padding: 10px 15px;
    text-decoration: none;
    color: #538088;
    transition: color ease 0.3s;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
}

.NavBar-admin-item {
    padding: 10px 20px;
    text-decoration: none;
    color: #fff;
    transition: color ease 0.3s;
    cursor: pointer;
}

.NavBar-locale {
    padding: 10px 20px;
    position: relative;
}

.NavBar-locale-current {
    width: 30px;
    cursor: pointer;
}

.NavBar-locale-dropdown {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    box-shadow: 5px 5px 20px rgba(0,0,0,0.3);
    border-radius: 3px;
    z-index: 2;
}

.NavBar-locale-dropdown.expanded {
    display: block;
}

.Navbar-locale-dropdown-item {
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.Navbar-locale-dropdown-item-icon {
    width: 30px;
    margin-right: 10px;
}

.NavBar-closebutton {
    padding: 10px 30px;
    color: #3a3a3a;
    display: none;
}

.NavBar-item:hover {
    color: #00a5ad;
    text-decoration: underline;
}

.NavBar-item:last-of-type {
    margin-right: 20px;
}

@media (max-width: 700px) {
    .NavBar-menubutton {
        display: block;
        cursor: pointer;
        color: #000;
        font-size: 20px;
    }
    
    .NavBar-items {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        padding-top: 20px;
        overflow-y: auto;
        overflow-x: hidden;
        flex-direction: column;
        background-color: #fff;
        text-align: left;
        z-index: 1;
        box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
    }

    .NavBar-items.expanded {
        display: flex;
    }
    
    .NavBar-closebutton {
        display: block;
        cursor: pointer;
    }

    .NavBar-item {
        padding: 10px 30px;
    }
}
