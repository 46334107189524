.AdminAddFriendPage {
    max-width: 900px;
    width: calc(100% - 20px);
    margin: 0 auto;
    text-align: left;
    padding-bottom: 50px;
}

.AdminAddFriendPage-actions {
    margin: 10px 0;
    text-align: right;
}
