.AdminHomePage .PageContent {
    max-width: 1200px;
    text-align: left;
    padding-bottom: 100px;
}

.AdminHomePage-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -20px;
}

.AdminHomePage-buttons .IcButton {
    height: 100px;
    width: 250px;
    line-height: 70px;
    text-align: center;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 20px;
}
