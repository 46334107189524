.FriendsPage-welcome {
    margin-bottom: 40px;
}

.FriendsPage-empty {
    text-align: center;
    display: block;
    padding: 30px 20px;
}

.FriendsPage-friends {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

.FriendsPage-friend {
    margin-bottom: 50px;
    text-align: left;
}

.FriendsPage-friend h2 {
    font-size: 20px;
}

.FriendsPage-friend-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.FriendsPage-friend-image {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    background-color: #ccc;
    position: relative;
}

.FriendsPage-friend-image img {
    width: 100%;
    height: 100%;
}

.FriendsPage-friend-description {
    padding: 0 20px;
}
