.EventsPage-hero-keke {
    background-image: url(./assets/hero_keke.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    position: absolute;
    bottom: 0;
    left: 70%;
    right: 0;
    height: 68%;
}

.EventsPage .PageContent {
    max-width: 940px;
}

.EventsPage-empty {
    text-align: center;
    text-align: center;
    display: block;
    padding: 30px 20px;
    flex-grow: 1;
    flex-shrink: 1;
}

.EventsPage-events {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.EventsPage-event {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 20px 10px;
}
