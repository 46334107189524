.Hero {
    position: relative;
    height: 535px;
    overflow: hidden;
    background-image: url(./assets/hero_bg.png);
    background-repeat: repeat-x;
}

.Hero-inner {
    max-width: 1137px;
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
}

.Hero-inner-top {
    background-image: url(./assets/hero_top.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left top;
    position: absolute;
    top: -20px;
    bottom: 0;
    left: 4%;
    right: 0;
    transform-origin: right bottom;
}

.Hero.animate .Hero-inner-top {
    animation: Hero-inner-top 1.5s ease-in-out;
}

@keyframes Hero-inner-top {
    0% {
        transform: rotate(45deg);
    }
    70% {
        transform: rotate(-0.2deg);
    }
    80% {
        transform: rotate(0.1deg);
    }
    88% {
        transform: rotate(-0.1deg);
    }
    100% {
        transform: rotate(0);
    }
}

.Hero-inner-title {
    position: absolute;
    top: 120px;
    width: 645px;
    max-width: 80%;
    left: 52px;
    font-size: 30px;
    background: #1d4f58;
    color: #fff;
    opacity: 0.9;
    text-align: right;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 50px;
    line-height: 1;
    margin: 0;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-weight: normal;
    text-shadow: 0 3px 0 rgba(0,0,0,0.53);
    white-space: nowrap;
}

.Hero.animate .Hero-inner-title {
    animation: Hero-inner-title 3s ease-in-out;
}

.Hero-inner-title-logo {
    position: absolute;
    left: 60px;
    height: 60px;
    top: -12px;
}

@keyframes Hero-inner-title {
    0% {
        transform: translateX(-150%);
    }
    50% {
        transform: translateX(-150%);
    }
    100% {
        transform: translateX(0%);
    }
}

@media (max-width: 800px) {
    .Hero-inner-title {
        left: 0;
        font-size: 22px;
        padding-right: 10px;
        max-width: calc(100% - 10px);
        box-sizing: border-box;
        min-width: fit-content;
        width: 350px;
        padding-left: 80px;
    }
    
    .Hero-inner-title-logo {
        top: -16px;
        left: 10px;
    }
}
