.Footer {
    background: #2b2b2b;
    text-align: center;
    color: #336068;
    font-size: 14px;
}

.Footer-main {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    max-width: 1137px;
    width: calc(100% - 20px);
    margin: 0 auto;
}

.Footer-left {
    flex-basis: 200px;
    flex-grow: 4;
    flex-shrink: 0;
}

.Footer-left-keke {
    width: 100%;
    max-width: 400px;
}

.Footer-center {
    flex-basis: 200px;
    flex-grow: 1;
    flex-shrink: 0;
    text-align: left;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

.Footer-right {
    flex-basis: 200px;
    flex-grow: 1;
    flex-shrink: 0;
    text-align: left;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

.Footer-link {
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    letter-spacing: 2px;
    color: #336068;
    text-decoration: none;
    padding: 10px 10px;
    cursor: pointer;
    transition: color ease 0.3s;
}

.Footer-link:hover {
    color: #00a5ad;
    text-decoration: underline;
}

.Footer-item {
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    letter-spacing: 2px;
    color: #336068;
    text-decoration: none;
    padding: 10px 10px;
    cursor: default;
}

.Footer-socialmedia {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.Footer-socialmedia-item {
    color: #545454;
    font-size: 40px;
    padding: 10px 10px;
    transition: color ease 0.3s;
}

.Footer-socialmedia-item:hover {
    color: #cecac3;
}

.Footer-copyright {
    padding-top: 150px;
    padding-bottom: 20px;
}

@media (max-width: 800px) {
    .Footer-main {
        flex-direction: column;
    }

    .Footer-right {
        padding-top: 0;
    }
}
