.ContactPage-hero-keke {
    background-image: url(./assets/hero_keke.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    position: absolute;
    bottom: 8%;
    left: 15%;
    right: 0;
    height: 68%;
}

.ContactPage-hero-damen {
    background-image: url(./assets/hero_damen.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.ContactPage-request {
    margin-bottom: 20px;
}

.ContactPage-more {
    margin-top: 100px;
}
