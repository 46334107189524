.AdminFilesPage .PageContent {
    max-width: 1200px;
    text-align: left;
    padding-bottom: 100px;
}

.AdminFilesPage-file {
    display: inline-flex;
    flex-direction: column;
    width: 100px;
    margin: 10px;
    flex-wrap: nowrap;
}

.AdminFilesPage-file:not(:last-of-type) {
    border-bottom: 1px solid #333;
}

.AdminFilesPage-file-preview img {
    width: 100px;
    height: 100px;
}

.AdminFilesPage-file-title {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 20px;
    text-align: center;
}

.AdminFilesPage-file-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.AdminFilesPage-file-action {
    padding: 10px 20px;
    cursor: pointer;
    text-decoration: none;
    color: #333;
}
