.LocationsPage-welcome {
    margin-bottom: 40px;
}

.LocationsPage-hero-keke {
    background-image: url(./assets/hero_keke.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    position: absolute;
    bottom: 0;
    left: 70%;
    right: 0;
    height: 68%;
}

.LocationsPage-empty {
    text-align: center;
    display: block;
    padding: 30px 20px;
}

.LocationsPage-locations {
    width: 600px;
    max-width: 100%;
    position: relative;
    margin: 0 auto;
}

.LocationsPage-location {
    outline: none;
    color: inherit;
    text-decoration: none;
    display: block;
    margin-bottom: 100px;
}

.LocationsPage-location-content {
    position: relative;
}

.LocationsPage-location-image {
    width: 100%;
    padding-bottom: 56%;
    background-position: center;
    background-size: cover;
    background-color: #ccc;
    background-repeat: no-repeat;
    position: relative;
}

.LocationsPage-location-abstract {
    padding: 10px 20px;
    background: rgba(0,0,0,0.7);
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity ease 0.3s;
}

.LocationsPage-location:hover .LocationsPage-location-abstract {
    opacity: 1;
}
