.EventPage .PageContent {
    text-align: left;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.EventPage .PageContent h2 {
    margin-top: 50px;
}

.EventPage-hero-img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    mask-image: linear-gradient(to right, rgba(0,0,0,0) 0%,  rgba(0,0,0,1) 10%, rgba(0,0,0,1) 90%, rgba(0,0,0,0) 100%)
}

.EventPage-description,
.EventPage-slideshow,
.EventPage-dates {
    margin-bottom: 50px;
}

.EventPage-date {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.EventPage-date:not(:last-of-type) {
    border-bottom: 1px solid #333;
}

.EventPage-date-info {
    flex-grow: 0;
    flex-shrink: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    text-align: left;
}

.EventPage-date-info-row:not(:first-of-type) {
    margin-top: 10px;
}

.EventPage-date-actions {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 10px 0;
    text-align: right;
}

.EventPage-date-actions .IcButton {
    width: 230px;
    margin: 0;
    text-align: center;
}

.EventPage-location {
    margin-bottom: 30px;
}
