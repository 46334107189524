@font-face {
  font-family: 'Caveat';
  src: local('Caveat'), url('assets/fonts/Caveat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Fredericka the Great';
  src: local('Fredericka the Great'), url('assets/fonts/FrederickatheGreat-Regular.ttf') format('truetype');
}

body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 20px;
    background-color: #eee;

    --color-primary: #064B37;

    --ic-button-border-radius: 0;
    --ic-button-padding: 10px 20px;
    --ic-button-letter-spacing: 1px;
    --ic-button-font-size: 16px;
    --ic-button-font-weight: normal;
    --ic-button-font-family: 'Muli', sans-serif;
    --ic-button-text-transform: uppercase;
    --ic-button-line-height: initial;

    --ic-button-background-primary: #fff;
    --ic-button-color-primary: #2b2b2b;
    --ic-button-border-primary: 2px solid #2b2b2b;
    --ic-button-box-shadow-primary: none;
    --ic-button-hover-transform-primary: none;
    --ic-button-hover-background-primary: #336068;
    --ic-button-hover-color-primary: #fff;
    --ic-button-hover-border-primary: 2px solid #336068;

    --ic-button-color-link: #333;
    --ic-button-hover-color-link: #666;

    --ic-spinner-border-color-primary: #064B37;

    --ic-errorbox-border-radius: 3px;

    --ic-table-head-cell-background: #cecac3;
    --ic-table-head-cell-border-radius: 0;
    
    --ic-input-field-border-radius: 0;
    --ic-input-field-border: 2px solid #2b2b2b;
    --ic-input-field-border-focus: 2px solid #336068;
    --ic-input-field-border-error: 2px solid #f42829;
}
