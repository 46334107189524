.CookieHintModal-content {
    max-width: 600px;
}

.CookieHintModal-selection {
    margin-top: 15px;
    margin-bottom: 05px;
}

.CookieHintModal-selection .InputCheckbox {
    margin-bottom: 10px;
}

.CookieHintModal-actions {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.CookieHintModal-imprint {
    flex-grow: 10;
    margin-top: 15px;
}

.CookieHintModal-imprint a {
    font-size: 14px;
}

.CookieHintModal-confirmselected {
    color: #666;
    flex-grow: 1;
    text-align: right;
    margin-left: 25px;
    margin-top: 15px;
    cursor: pointer;
}

.CookieHintModal-edit {
    color: #666;
    flex-grow: 1;
    text-align: right;
    margin-left: 25px;
    margin-top: 15px;
    cursor: pointer;
}

.CookieHintModal-confirmall {
    flex-grow: 1;
    text-align: right;
    margin-left: 25px;
    margin-top: 15px;
}

.CookieHintModal-confirmall .link {
    font-weight: bold;
} 