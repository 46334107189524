.SuccessBox {
    border: 2px solid #00aa00;
    padding: 10px 20px;
    margin: 10px 0;
    color: #00aa00;
    white-space: nowrap;
}

.SuccessBox svg {
    vertical-align: middle;
    font-size: 30px;
    margin-right: 20px;
}

.SuccessBox-message {
    display: inline-block;
    white-space: normal;
    vertical-align: middle;
}