.InfoBox {
    background: rgba(255,255,255,0.05);
    border-radius: 3px;
    border: 2px solid #226;
    padding: 10px 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 10px;
    color: #226;
    margin: 10px 0;
}

.InfoBox-icon {
    font-size: 30px;
    margin-right: 20px;
}

.InfoBox-text {
    flex-grow: 1;
    flex-shrink: 1;
}

.InfoBox-text .Button {
    margin: 0 10px;
}
