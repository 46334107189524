.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App .NavBar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.App-content {
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}
