.TextBlock-text {
    text-align: left;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.TextBlock-text code {
    white-space: pre-wrap;
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    display: inline-block;
    padding: 1px 5px;
    margin-bottom: 3px;
    border-radius: 2px;
}

.TextBlock-text pre code {
    display: block;
    padding: 10px 15px;
    border: 0;
    color: #f5f5f5;
    background-color: #333;
}

.TextBlock-text table {
    border-collapse: collapse;
}

.TextBlock-text table thead tr th,
.TextBlock-text table tbody tr td {
    text-align: left;
    padding: 3px 10px;
}

.TextBlock-text table thead tr th {
    border-bottom: 1px solid #ddd;
}

.TextBlock-text blockquote {
    font-style: italic;
    color: #777;
    margin-left: 0;
    margin-bottom: 3px;
}

.TextBlock-text img {
    margin: 20px auto;
    display: block;
    max-width: 100%;
}

.TextBlock-action {
    cursor: pointer;
}

.TextBlock-form .Button {
    margin: 0 10px;
}