.AdminAddEventPage .PageContent {
    max-width: 1200px;
    text-align: left;
    padding-bottom: 100px;
}

.AdminAddEventPage-dates {
    border: 1px solid #ccc;
    padding: 20px;
}

.AdminAddEventPage-date {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
}

.AdminAddEventPage-date .IcButton {
    margin-top: 15px;
    margin-bottom: 15px;
}

.AdminAddEventPage-date:not(:last-of-type) {
    border-bottom: 1px solid #ccc;
}

.AdminAddEventPage-actions {
    margin: 10px 0;
    text-align: right;
}
