.HomePage {
    text-align: left;
    position: relative;
}

.HomePage h2 {
    margin: 30px 0;
}

.HomePage h2,
.HomePage h2 > a {
    color: #bbae79;
    font-size: 34px;
    letter-spacing: 5px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Times New Roman', Times, serif;
    margin-top: 30px;
    margin-bottom: 30px;
    text-decoration: none;
    text-align: center;
    line-height: 1;
}

.HomePage h2::after {
    display: block;
    content: '';
    position: relative;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    height: 2px;
    background: #bbae79;
}

.HomePage-hero-inner-centerleft {
    background-image: url(./assets/hero_centerleft.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 4%;
    right: 0;
    opacity: 0.85;
}

.Hero.animate .HomePage-hero-inner-centerleft {
    animation: HomePage-hero-inner-centerleft 4s ease;
}

@keyframes HomePage-hero-inner-centerleft {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0.85;
    }
}

.HomePage-hero-inner-centerright {
    background-image: url(./assets/hero_centerright.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.HomePage-hero-inner-bottom {
    background-image: url(./assets/hero_bottom.png);
    background-repeat: no-repeat;
    background-position: left bottom;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.Hero.animate .HomePage-hero-inner-bottom {
    animation: HomePage-hero-inner-centerleft 5.5s ease;
}

@keyframes HomePage-hero-inner-bottom {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.HomePage-welcome {
    padding: 30px 0;
    background: #fff;
}

.HomePage-welcome-inner {
    max-width: 900px;
    width: calc(100% - 20px);
    margin: 0 auto;
    text-align: center;
}

.HomePage-welcome-socialmedia {
    text-align: center;
    margin-top: 50px;
}

.HomePage-welcome-socialmedia a {
    text-decoration: none;
    color: inherit;
    font-size: 30px;
    padding: 10px 20px;
}

.HomePage-links {
    background: #fcf5eb;
    padding: 50px 0;
}

.HomePage-links-inner {
    max-width: 1000px;
    width: calc(100% - 20px);
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.HomePage-links-link {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 230px;
    text-align: center;
    text-align: center;
    text-decoration: none;
}

.HomePage-links-link-label {
    color: #2b2b2b;
    font-size: 20px;
    text-transform: uppercase;
    padding-bottom: 30px;
}

.HomePage-links-link-inner {
    height: 148px;
    width: 148px;
    position: relative;
    display: inline-block;
}

.HomePage-links-link-inner::before {
    position: absolute;
    content: '';
    display: block;
    background: #d6ccad;
    bottom: 5px;
    right: 0;
    width: 134px;
    height: 134px;
    z-index: 0;
    border-radius: 200px;
    transition: bottom ease 0.3s, right ease 0.3s;
}

.HomePage-links-link-inner:hover::before {
    bottom: 0;
    right: 13px;
}

.HomePage-links-link-inner > img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 148px;
} 

.HomePage-events {
    background: #fff;
    padding: 30px 0;
    padding-bottom: 80px;
}

.HomePage-events-inner {
    max-width: 940px;
    width: calc(100% - 20px);
    margin: 0 auto;
}

.HomePage-events .EventPreview {
    flex-grow: 0;
    flex-shrink: 0;
    margin: 20px 10px;
}

.HomePage-events-empty {
    text-align: center;
    display: block;
    padding: 30px 20px;
}

.HomePage-events-more {
    text-align: center;
    padding: 30px;
    display: block;
}

.HomePage-newsletter-inner {
    max-width: 900px;
    width: calc(100% - 20px);
    margin: 0 auto;
    text-align: center;
    padding: 50px 0;
}

.HomePage-newsletter-actions {
    margin-top: 30px;
}

.HomePage-video {
    background: #cecac3;
    padding-top: 30px;
    padding-bottom: 80px;
    text-align: center;
}

.HomePage-video h2 {
    color: #336068;
}

.HomePage-video h2::after {
    background: #336068;
}

.HomePage-video video {
    max-width: 600px;
    width: calc(100% - 20px);
    border: 10px solid #2b2b2b;
}

@media (max-width: 800px) {
    .HomePage-links-inner {
        flex-direction: column;
    }

    .HomePage-links-link {
        margin-bottom: 40px;
    }
}

@media (max-width: 1200px) {
    .HomePage-hero-inner-centerright {
        display: none;
    }
}
